header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

header h1, header h3 {
    font-family: 'Anonymous Pro', monospace;
}