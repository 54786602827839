@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap');

.container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.btnCont {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Button Styling */
.btnCont button {
    --colorSolve: #1a8516;
    --colorSolve2: #236b19;
    --colorReset: #851616;
    --colorReset2: #6b1919;
    --colorVerify: #162585;
    --colorVerify2: #191f6b;
    perspective: 500px;
    padding: 0.8em 0.8em;
    background: linear-gradient(var(--colorSolve), var(--colorSolve2));
    border: none;
    outline: none;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: #fff;
    text-shadow: 0 10px 10px #000;
    cursor: pointer;
    transform: rotateX(70deg) rotateZ(30deg);
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.btnCont button::before {
    content: "";
    width: 100%;
    height: 15px;
    background-color: var(--colorSolve2);
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotateX(90deg);
    transform-origin: bottom;
}

.btnCont button::after {
    content: "";
    width: 15px;
    height: 100%;
    background-color: var(--colorSolve);
    position: absolute;
    top: 0;
    right: 0;
    transform: rotateY(-90deg);
    transform-origin: right;
}

.btnCont button:hover {
    transform: rotateX(30deg) rotateZ(0);
}

/* Verify Button Color */
.btnCont button:nth-child(2) {
    background: linear-gradient(var(--colorVerify), var(--colorVerify2));
}

.btnCont button:nth-child(2)::before {
    background-color: var(--colorVerify);
}

.btnCont button:nth-child(2)::after {
    background-color: var(--colorVerify2);
}

/* Reset Button Color */
.btnCont button:nth-child(3) {
    background: linear-gradient(var(--colorReset), var(--colorReset2));
}

.btnCont button:nth-child(3)::before {
    background-color: var(--colorReset);
}

.btnCont button:nth-child(3)::after {
    background-color: var(--colorReset2);
}



/* Input Styling */
input[type="number"] {
    width: 40px;
    height: 40px;
    border-radius: 15px;
    font-size: x-large;
    text-align: center;
    margin: 5px;
    outline-color: #fff;
    font-family: 'Anonymous Pro', monospace;
    transition: all 0.5s ease-in-out;
    background-color: #eee;
    border: none;
    color: black;
    box-shadow: 0 0.3rem #dfd9d9;
}

input[type="number"]:focus {
    outline-color: lightcoral;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Lines In Board */
.border {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.border:nth-child(1) .border-right,
.border:nth-child(4) .border-right,
.border:nth-child(7) .border-right {
    padding-top: 0.5%;
}

.border-right {
    padding-bottom: 0.5%;
}

.border-right:nth-child(3n) {
    border-right: 5px solid;
    padding-right: 0.5%;
}

.border-right:nth-child(4),
.border-right:nth-child(7) {
    padding-left: 0.5%;
}

.border:nth-child(3n) .border-right {
    border-bottom: 5px solid;
}

.border:nth-child(9) .border-right {
    border-bottom: 0;
}

.border-right:nth-child(9) {
    border-right: 0;
}

/* Border Radius */
.border:nth-child(1) .border-right:nth-child(1) {
    border-radius: 15px 0 0 0;
}

.border:nth-child(1) .border-right:nth-child(9) {
    border-radius: 0 15px 0 0;
}

.border:nth-child(9) .border-right:nth-child(1) {
    border-radius: 0 0 0 15px;
}

.border:nth-child(9) .border-right:nth-child(9) {
    border-radius: 0 0 15px 0;
}

.border-right {
    background-color: #dfd9d9;
}

/* Background */
.border:nth-child(n+1):nth-child(-n+3) .border-right:nth-child(n+1):nth-child(-n+3),
.border:nth-child(n+1):nth-child(-n+3) .border-right:nth-child(n+7):nth-child(-n+9) {
    background-color: rgb(253, 162, 162);
}

.border:nth-child(n+4):nth-child(-n+6) .border-right:nth-child(n+4):nth-child(-n+6) {
    background-color: rgb(253, 162, 162);
}

.border:nth-child(n+7):nth-child(-n+9) .border-right:nth-child(n+1):nth-child(-n+3),
.border:nth-child(n+7):nth-child(-n+9) .border-right:nth-child(n+7):nth-child(-n+9) {
    background-color: rgb(253, 162, 162);
}

/* Responsiveness */
@media (max-width: 576px) {
    input[type="number"] {
        width: 25px;
        height: 25px;
        font-size: 18px;
        border-radius: 8px;
        box-shadow: 0 0.2rem #dfd9d9;
    }

    .container {
        flex-direction: column;
    }

    .btnCont {
        padding-top: 5%;
        width: 300px;
        height: 100%;
        /* display: flex;
        align-items: center; */
        justify-content: space-evenly;
    }
}